import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
// import Template from "../views/Template.vue";
// import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: '',
    component: () => import('../components/Theme/Main'),
    children: [
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
      },
      {
        path:'/home',
        name:'home',
        component:() => import('../views/Page/Dashboard.vue')
      },
      {
        path:'/Pasien/add',
        name:'PasienAdd',
        component:() => import('../views/Page/AddPasien.vue')
      },
      {
        path:'/Pasien/list',
        name:'PasienList',
        component:() => import('../views/Page/Pasien/List.vue')
      },
      {
        path:'/Pasien/edit/:rm',
        name:'Pasienedit',
        component:() => import('../views/Page/Pasien/EditPasien.vue')
      },
      {
        path:'/Registrasi/add',
        name:'RegAdd',
        component:() => import('../views/Page/Registrasi.vue')
      },
      {
        path:'/Registrasi/List',
        name:'RegList',
        component:() => import('../views/Page/Registrasi/List.vue')
      },
      {
        path:'/Pemeriksaan/:register',
        name:'Pemeriksaan',
        component:() => import('../views/Page/Pemeriksaan/Pemeriksaan.vue')
      },
      {
        path:'/Master/dokter',
        name:'Dokterlist',
        component:() => import('../views/Page/Master/Dokter/List.vue')
      },
      {
        path:'/Master/dokterEdit/:id',
        name:'Editdokter',
        component:() => import('../views/Page/Master/Dokter/Edit.vue')
      },
      {
        path:'/Master/addDokter/',
        name:'Adddokter',
        component:() => import('../views/Page/Master/Dokter/Add.vue')
      },
      {
        path:'/Master/obat',
        name:'Obatslist',
        component:() => import('../views/Page/Master/Obat/List.vue')
      },
      {
        path:'/Master/obatAdd',
        name:'AddObat',
        component:() => import('../views/Page/Master/Obat/Add.vue')
      },
      {
        path:'/Master/keterangan1',
        name:'Keterangan1',
        component:() => import('../views/Page/Master/Obat/Keterangan1.vue')
      },
      {
        path:'/Master/keterangan2',
        name:'Keterangan2',
        component:() => import('../views/Page/Master/Obat/Keterangan2.vue')
      },
      {
        path:'/Master/keteranganwaktu',
        name:'Keteranganwaktu',
        component:() => import('../views/Page/Master/Obat/Keteranganwaktu.vue')
      },
      {
        path:'/Master/Satuandosis',
        name:'Satuandosis',
        component:() => import('../views/Page/Master/Obat/Satuandosis.vue')
      },
      {
        path:'/Pasien/group',
        name:'GroupPasien',
        component:() => import('../views/Page/Pasien/Group.vue')
      },
      {
        path:'/Profile',
        name:'profile',
        component:() => import('../views/Page/Profile.vue')
      },
      {
        path:'/Transaksi/List',
        name:'TrsList',
        component:() => import('../views/Page/Billing/List.vue')
      },
      {
        path:'/Billing/:reg',
        name:'TrsBill',
        component:() => import('../views/Page/Billing/TransaksiTindakan.vue')
      },
    ]
  }, {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/LoginView.vue')
  },
  {
    path: '/satusehat',
    name: 'satusehat',
    component: () => import('../components/Theme/Main'),
    children :[
      {
        path: '/Satusehat/send',
        name: 'sendasatusehat',
        component: () => import('../views/Page/Satusehat.vue')
      },
    ]
  },
  {
    path:'/Monitor/:klinik',
    name:'Monitorlist',
    component:() => import('../views/Page/Monitor.vue'),
    beforeEnter (to, from, next) {
      // console.log(to);
    return next()
    }
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter (to, from, next) {
      store.dispatch('logout').then(() => {
      if (from.meta.requiresAuth) {
          return next('/');
      }
        location.reload();
      })
    }
  },
  {
    path: '/view',
    name: 'view',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Pemeriksaan/viewResep.vue')
  },
  {
    path: "*",
    name:"notFound",
    component: () =>
    import(/* webpackChunkName: "about" */ "@/views/NotFound"),
  },
  

]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

